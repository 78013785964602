import { useAuthStore } from '~/store/auth.js'
import { useGridStore } from '~/store/grid'

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return

  const authStore = useAuthStore()
  const gridStore = useGridStore()

  if (!authStore.isAuthenticated) {
    return navigateTo('/login')
  }

  if (authStore.isAuthenticated) {
    await authStore
      .fetchUser()
      .then(gridStore.getGrids)
      .catch(() => authStore.logout())
  }
})
